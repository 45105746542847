import algoliasearch from 'algoliasearch';
import { graphql } from 'gatsby';
import React, { createRef, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InstantSearch } from 'react-instantsearch-dom';

import ContentCol from '../components/contentCol/contentCol';
import EscapeCard from '../components/escapeCard/escapeCard';
import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import SearchField from '../components/search/searchField';
import SearchResult from '../components/search/searchResult';
import SeoPress from '../components/seoPress/seoPress';
import { useOnClickOutside } from '../utils/useOnClickOutside';

const searchIndices = [{ name: `prod_escapes`, title: `Escapes (Production)` }];

const EscapesPage = ({ data }) => {
  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  useOnClickOutside(rootRef, () => setFocus(false));

  return (
    <Layout navbarOnWhite={true}>
      <SeoPress
        seo={data.wpSitePage.seo}
        title={data.wpSitePage.title}
        uri={``}
      />

      <main ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          indexName={searchIndices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <Container className="pt-7">
            <Row className="justify-content-center">
              <ContentCol>
                <h1 className="text-center mb-4">The Latest Mindful Escapes</h1>
                <SearchField
                  onFocus={() => setFocus(true)}
                  hasFocus={hasFocus}
                />
              </ContentCol>
            </Row>
          </Container>

          <section className="py-5">
            <Container fluid={true} className="px-3 px-lg-4">
              <SearchResult
                show={query && query.length > 0 && hasFocus}
                indices={searchIndices}
              />
            </Container>
          </section>
        </InstantSearch>

        <section className="pt-4 pt-md-5 pb-6 pb-md-7">
          <NewsletterBanner />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpSitePage(databaseId: { eq: 742 }) {
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }
    wp {
      sharedSiteContent {
        siteNewsletterSignup {
          headline
        }
      }
    }
    latestEscapes: allWpEscape(sort: { fields: date, order: DESC }) {
      nodes {
        ...cardEscape
      }
    }
  }
`;

export default EscapesPage;
