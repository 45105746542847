import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { connectHits, Index, PoweredBy } from 'react-instantsearch-dom';

import * as colors from '../../styles/modules/colors.module.scss';

/*const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PageHit = ({ hit }) => (
  <div>
    <Link to={hit.path}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)*/

// Todo: DRY out with Escape Card
const HitCard = ({ hit }) => {
  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  return (
    <Link to={hit.path} key={hit.objectID}>
      <Card className="post-card">
        <BackgroundImage
          className="card-img"
          as="header"
          fluid={hit.thumbnailFluid}
        >
          <div className="image-overlay">
            <div>
              <h2 className="my-1">{hit.title}</h2>
            </div>
          </div>

          <div className="card-logo">
            <div
              className="bg-white border-light rounded-circle overflow-hidden position-relative"
              style={{ borderStyle: 'solid', borderWidth: '1px' }}
            >
              {hit.provider.userProviderProfile.logo?.localFile.childImageSharp
                .gatsbyImageData ? (
                <GatsbyImage
                  image={
                    hit.provider.userProviderProfile.logo.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  className="transition-fix"
                  alt={hit.provider.userProviderProfile.logo.altText}
                  backgroundColor={colors.light}
                />
              ) : (
                <div
                  className="text-center bg-primary text-white"
                  style={{ textShadow: `0 0 2px ${colors.dark}` }}
                >
                  &#9829;
                </div>
              )}
            </div>
          </div>

          <div className="card-meta">
            <Badge pill variant="light">
              {hit.categories[0]?.name}
            </Badge>
            {` `}
            <Badge pill variant="light">
              {hit.geographies[0]?.name}
            </Badge>
          </div>
        </BackgroundImage>

        <Card.Text>
          {hit.excerpt.length > 1
            ? hit.excerpt
            : truncate(hit.description, 120).replace(/<\/?[^>]+(>|$)/g, '')}
        </Card.Text>
      </Card>
    </Link>
  );
};

const Hits = ({ hits }) => (
  <Row>
    {hits.map((hit) => (
      <Col xs={12} md={6} xl={4} className="mb-4">
        <HitCard hit={hit} />
      </Col>
    ))}
  </Row>
);

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    {/*<HitCount />*/}
    <CustomHits />
  </Index>
);

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
);

export default SearchResult;
