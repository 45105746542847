import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className ? `search-form ${className}` : 'search-form'}>
      <input
        className="d-block w-100 search-field"
        type="text"
        placeholder="Search Escapes"
        aria-label="Search Escapes"
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </form>
  )
);

const SearchField = () => {
  return <SearchBox />;
};

export default SearchField;
