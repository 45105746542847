import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Card } from 'react-bootstrap';

import * as colors from '../../styles/modules/colors.module.scss';

export const cardEscape = graphql`
  fragment cardEscape on WpEscape {
    title
    slug
    author {
      node {
        slug
        avatar {
          url
        }
        userProviderProfile {
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 120, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
    seo {
      metaDesc
    }
    categories {
      nodes {
        name
      }
    }
    geographies {
      nodes {
        name
      }
    }
    escape {
      description
      location {
        destination {
          streetAddress
        }
      }
      images {
        localFile {
          ...imageFluidMedium
        }
      }
    }
  }
`;

const EscapeCard = ({ escape }) => {
  const data = useStaticQuery(graphql`
    query EscapeCardQuery {
      fallbackImage: file(relativePath: { eq: "backgrounds/bg-fallback.jpg" }) {
        ...imageFluidMedium
      }
    }
  `);

  const provider = escape.author.node;
  const escapeContent = escape.escape;

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  return (
    <Link to={`/${provider.slug}/${escape.slug}`}>
      <Card className="post-card">
        <BackgroundImage
          className="card-img"
          as="header"
          fluid={
            escapeContent.images[0].localFile.childImageSharp.fluid ||
            data.fallbackImage.childImageSharp.fluid
          }
        >
          <div className="image-overlay">
            <div>
              <h2 className="my-1">{escape.title}</h2>
              {/*<p>{escapeContent.location.destination.streetAddress}</p>*/}
            </div>
          </div>

          <div className="card-logo">
            <div
              className="bg-white border-light rounded-circle overflow-hidden position-relative"
              style={{ borderStyle: 'solid', borderWidth: '1px' }}
            >
              {provider.userProviderProfile.logo?.localFile.childImageSharp
                .gatsbyImageData ? (
                <GatsbyImage
                  image={
                    provider.userProviderProfile.logo.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  className="transition-fix"
                  alt={provider.userProviderProfile.logo.altText}
                  backgroundColor={colors.light}
                />
              ) : (
                <div
                  className="text-center bg-light text-white"
                  style={{ textShadow: `0 0 2px ${colors.dark}` }}
                >
                  &#9829;
                </div>
              )}
            </div>
          </div>

          <div className="card-meta">
            <Badge pill variant="light">
              {escape.categories.nodes[0]?.name}
            </Badge>
            {` `}
            <Badge pill variant="light">
              {escape.geographies.nodes[0]?.name}
            </Badge>
          </div>
        </BackgroundImage>

        <Card.Text>
          {escape.seo.metaDesc.length > 1
            ? escape.seo.metaDesc
            : truncate(escapeContent.description, 120).replace(
                /<\/?[^>]+(>|$)/g,
                ''
              )}
        </Card.Text>
      </Card>
    </Link>
  );
};

export default EscapeCard;

EscapeCard.propTypes = {
  escape: PropTypes.object.isRequired,
};
